import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createBlock as _createBlock, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "rounded bg-danger-200 h-12 flex items-center justify-center text-alert-900 text-sm font-semibold my-8"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "flex flex-col space-y-4" }
const _hoisted_4 = { class: "text-gray-500" }
const _hoisted_5 = { class: "flex flex-col" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "flex flex-col space-y-4" }
const _hoisted_8 = { class: "text-gray-500" }
const _hoisted_9 = { class: "flex flex-col" }
const _hoisted_10 = {
  key: 2,
  class: "flex flex-col space-y-10"
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = {
  key: 1,
  class: "space-y-8"
}
const _hoisted_13 = { class: "mt-2 font-semibold leading-tight text-center" }
const _hoisted_14 = { class: "space-y-4" }
const _hoisted_15 = {
  key: 2,
  class: "space-y-8"
}
const _hoisted_16 = { class: "mt-2 font-semibold leading-tight text-center" }
const _hoisted_17 = { class: "space-y-4" }
const _hoisted_18 = { class: "flex justify-center" }
const _hoisted_19 = {
  key: 3,
  class: "flex flex-col space-y-4"
}
const _hoisted_20 = {
  key: 0,
  class: "text-lg"
}
const _hoisted_21 = {
  key: 1,
  class: "p-4 border rounded bg-gray-200"
}
const _hoisted_22 = {
  key: 2,
  class: "p-4 border rounded bg-gray-200"
}
const _hoisted_23 = {
  key: 4,
  class: "p-2 mt-4 font-semibold rounded text-danger-500 size-18",
  "data-dusk": "login-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTextInput = _resolveComponent("BaseTextInput")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_QRCodeViewer = _resolveComponent("QRCodeViewer")!
  const _component_PhoneNumberInput = _resolveComponent("PhoneNumberInput")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_ButtonLink = _resolveComponent("ButtonLink")!
  const _component_LoginLayout = _resolveComponent("LoginLayout")!

  return (_openBlock(), _createBlock(_component_LoginLayout, null, {
    default: _withCtx(() => [
      (_ctx.step === 1)
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            class: "space-y-8",
            onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.attemptLogin && _ctx.attemptLogin(...args)), ["prevent"]))
          }, [
            (_ctx.env !== 'prod')
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, " Environment: " + _toDisplayString(_ctx.env.toUpperCase()), 1))
              : _createCommentVNode("", true),
            _createVNode(_component_BaseTextInput, {
              modelValue: _ctx.username,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event)),
              name: "username",
              label: _ctx.$t('platform.login.username'),
              placeholder: _ctx.$t('platform.login.username-placeholder'),
              "data-dusk": "username"
            }, null, 8, ["modelValue", "label", "placeholder"]),
            _createVNode(_component_BaseTextInput, {
              modelValue: _ctx.password,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.password) = $event)),
              name: "password",
              label: _ctx.$t('platform.login.password'),
              type: "password",
              placeholder: _ctx.$t('platform.login.password-placeholder'),
              "data-dusk": "password"
            }, {
              action: _withCtx(() => [
                _createElementVNode("span", {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toResetPassword()))
                }, _toDisplayString(_ctx.$t('platform.login.forgot-password')), 1)
              ]),
              _: 1
            }, 8, ["modelValue", "label", "placeholder"]),
            _createVNode(_component_BaseButton, {
              "data-cy": "login-btn",
              type: "submit",
              class: "flex w-full mt-8 text-center btn-large",
              loading: _ctx.loading
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('platform.login.login')), 1)
              ]),
              _: 1
            }, 8, ["loading"])
          ], 32))
        : _createCommentVNode("", true),
      (_ctx.step === 2)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.mfa_type === 'otp')
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("strong", _hoisted_4, _toDisplayString(_ctx.$t('platform.login.qr-code-message')), 1),
                    (_ctx.barcode_uri)
                      ? (_openBlock(), _createBlock(_component_QRCodeViewer, {
                          key: 0,
                          class: "place-self-center",
                          value: _ctx.barcode_uri,
                          size: _ctx.barcode_size
                        }, null, 8, ["value", "size"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_BaseTextInput, {
                      modelValue: _ctx.otp_code,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.otp_code) = $event)),
                      placeholder: _ctx.$t('platform.login.verify-code'),
                      label: _ctx.$t('platform.login.otp-code-label'),
                      error: _ctx.errorMessage,
                      onKeydownEvent: _ctx.associate
                    }, null, 8, ["modelValue", "placeholder", "label", "error", "onKeydownEvent"]),
                    _createVNode(_component_BaseButton, {
                      "data-cy": "verify-mfa-btn",
                      type: "submit",
                      class: _normalizeClass([_ctx.otp_code ? '' : 'btn-disabled', "btn-large"]),
                      disabled: _ctx.isVerifing,
                      loading: _ctx.isVerifing,
                      onClick: _withModifiers(_ctx.associate, ["prevent"])
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('platform.common.verify')), 1)
                      ]),
                      _: 1
                    }, 8, ["class", "disabled", "loading", "onClick"])
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_BaseButton, {
                      class: "btn-large my-6",
                      color: "ghost",
                      disabled: _ctx.loading,
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.mfa_type = 'sms'))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('platform.login.use-sms')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ])
                ]))
              : (_ctx.mfa_type === 'sms')
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("strong", _hoisted_8, _toDisplayString(_ctx.$t('platform.login.enter-number-message')), 1),
                      _createVNode(_component_PhoneNumberInput, {
                        id: "phone",
                        ref: "phone",
                        modelValue: _ctx.contactNumber,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.contactNumber) = $event)),
                        class: "text-left",
                        label: _ctx.$t('platform.login.phone-number')
                      }, null, 8, ["modelValue", "label"]),
                      _createVNode(_component_BaseButton, {
                        class: _normalizeClass(_ctx.contactNumber ? '' : 'btn-disabled'),
                        color: "ghost",
                        disabled: _ctx.loading,
                        onClick: _ctx.fetchCurrentUserSMS
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('platform.login.get-code')), 1)
                        ]),
                        _: 1
                      }, 8, ["class", "disabled", "onClick"]),
                      _createVNode(_component_BaseTextInput, {
                        modelValue: _ctx.sms_code,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.sms_code) = $event)),
                        placeholder: _ctx.$t('platform.login.verify-code'),
                        label: _ctx.$t('platform.login.otp-code-label'),
                        error: _ctx.errorMessage,
                        onKeydownEvent: _ctx.associate
                      }, null, 8, ["modelValue", "placeholder", "label", "error", "onKeydownEvent"]),
                      _createVNode(_component_BaseButton, {
                        class: _normalizeClass([_ctx.sms_code ? '' : 'btn-disabled', "btn-large"]),
                        disabled: _ctx.loading,
                        onClick: _ctx.associate
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('platform.common.verify')), 1)
                        ]),
                        _: 1
                      }, 8, ["class", "disabled", "onClick"])
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_BaseButton, {
                        disabled: _ctx.loading,
                        class: "btn-large my-6",
                        color: "ghost",
                        onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.mfa_type = 'otp'))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('platform.login.use-otp')), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled"])
                    ])
                  ]))
                : _createCommentVNode("", true),
            _createElementVNode("button", {
              type: "button",
              class: "mt-4 font-semibold text-gray-500",
              onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.step = 1))
            }, [
              _createVNode(_component_BaseIcon, { name: "arrow-left" }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('platform.common.back')), 1)
            ])
          ], 64))
        : _createCommentVNode("", true),
      (_ctx.step === 3)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            (_ctx.useRecoveryCode)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createVNode(_component_BaseTextInput, {
                    modelValue: _ctx.input_recovery_code,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.input_recovery_code) = $event)),
                    label: _ctx.$t('platform.login.enter-recovery-code'),
                    type: "password",
                    placeholder: _ctx.$t('platform.login.enter-recovery-code'),
                    error: _ctx.errorMessage
                  }, null, 8, ["modelValue", "label", "placeholder", "error"]),
                  _createVNode(_component_BaseButton, {
                    disabled: _ctx.loading,
                    size: "large",
                    class: "mt-4 w-full",
                    onClick: _ctx.associate
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('platform.common.submit')), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "onClick"])
                ]))
              : (_ctx.mfa_type === 'otp')
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.$t('platform.login.otp-code-message')), 1),
                    _createElementVNode("div", _hoisted_14, [
                      _createVNode(_component_BaseTextInput, {
                        modelValue: _ctx.otp_code,
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.otp_code) = $event)),
                        label: _ctx.$t('platform.login.otp-code-label'),
                        type: "password",
                        placeholder: _ctx.$t('platform.login.verify-code'),
                        error: _ctx.errorMessage,
                        onKeydownEvent: _ctx.associate
                      }, null, 8, ["modelValue", "label", "placeholder", "error", "onKeydownEvent"]),
                      _createVNode(_component_BaseButton, {
                        disabled: _ctx.isVerifing,
                        loading: _ctx.isVerifing,
                        size: "large",
                        class: "w-full",
                        onClick: _withModifiers(_ctx.associate, ["prevent"])
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('platform.common.verify')), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled", "loading", "onClick"])
                    ])
                  ]))
                : (_ctx.mfa_type === 'sms')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.$t('platform.login.otp-verify-message', { contactNumber: _ctx.contactNumber })), 1),
                      _createElementVNode("div", _hoisted_17, [
                        _createVNode(_component_BaseTextInput, {
                          modelValue: _ctx.sms_code,
                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.sms_code) = $event)),
                          label: _ctx.$t('platform.login.otp-code-label'),
                          type: "password",
                          placeholder: _ctx.$t('platform.login.verify-code'),
                          error: _ctx.errorMessage,
                          onKeydownEvent: _ctx.associate
                        }, null, 8, ["modelValue", "label", "placeholder", "error", "onKeydownEvent"]),
                        _createVNode(_component_BaseButton, {
                          disabled: _ctx.isVerifing,
                          loading: _ctx.isVerifing,
                          size: "large",
                          class: "w-full",
                          onClick: _withModifiers(_ctx.associate, ["prevent"])
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('platform.common.verify')), 1)
                          ]),
                          _: 1
                        }, 8, ["disabled", "loading", "onClick"])
                      ])
                    ]))
                  : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("button", {
                type: "button",
                class: "font-medium text-primary-500 hover:text-primary-900 focus:outline-none",
                onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.toggleRecoveryCode && _ctx.toggleRecoveryCode(...args)))
              }, _toDisplayString(_ctx.useRecoveryCode ? _ctx.$t(`platform.login.use-${_ctx.mfa_type}`) : _ctx.$t('platform.login.login-recovery-code')), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.step === 4)
        ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
            (_ctx.recovery_code)
              ? (_openBlock(), _createElementBlock("p", _hoisted_20, _toDisplayString(_ctx.$t('platform.login.recovery-code-message')), 1))
              : _createCommentVNode("", true),
            (_ctx.recovery_code)
              ? (_openBlock(), _createElementBlock("div", _hoisted_21, _toDisplayString(_ctx.recovery_code), 1))
              : (_openBlock(), _createElementBlock("div", _hoisted_22, _toDisplayString(_ctx.$t('platform.login.success')), 1)),
            _createVNode(_component_ButtonLink, {
              "data-cy": "continue-btn",
              autofocus: "",
              class: "btn-large",
              onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.postLoginRedirect()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('platform.common.continue')), 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (_ctx.errorMessage && ![2, 3].includes(_ctx.step))
        ? (_openBlock(), _createElementBlock("div", _hoisted_23, _toDisplayString(_ctx.errorMessage), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}